const config = {
    apiKey: "AIzaSyCSdHoNrBu2Z344TwXV5HzX-Mz80nqtji8",
    authDomain: "hyperglade-finstep.firebaseapp.com",
    projectId: "hyperglade-finstep",
    storageBucket: "hyperglade-finstep.appspot.com",
    messagingSenderId: "848198212014",
    appId: "1:848198212014:web:9d80fbda5bc21982db103f",
    measurementId: "G-BQ2KGFQBER"
}

export default config;