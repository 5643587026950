// firebase.js
// contains the Firebase context and provider

import React, { createContext, useEffect, useState } from 'react'
import firebaseConfig from './firebaseConfig';
import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const FirebaseContext = createContext(null)
export { FirebaseContext }

function FirebaseProvider({ children }){
    const [firebase, setFirebase] = useState({
        app: null,
        database: null,
        analytics: null
    });

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        const database = getFirestore(app);
        const auth = getAuth(app);

        setFirebase({
            app: app,
            analytics: analytics,
            database: database,
            auth: auth,
        })
    }, []);

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider;