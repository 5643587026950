import './App.scss';
import { useEffect, useState } from 'react';
import EnterEmailPage from './pages/EnterEmailPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import WalletVerificationPage from './pages/WalletVerificationPage';
import RegistrationCompletePage from './pages/RegistrationCompletePage';
import { Routes } from './contants';


function App() {
	const [route, setRoute] = useState(Routes.EnterEmailPageRoute);

	useEffect(() => {
		const path = window.location.pathname;
		const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		const email = params.email;

		if(path === '/verify' && email){
			setRoute(Routes.WalletVerificationPageRoute);
		}
	}, []);

	const renderPage = () => {
		if(route === Routes.EnterEmailPageRoute){
			return <EnterEmailPage setRoute={setRoute} />
		}

		if(route === Routes.EmailVerificationPageRoute){
			return <EmailVerificationPage setRoute={setRoute} />
		}

		if(route === Routes.WalletVerificationPageRoute){
			return <WalletVerificationPage setRoute={setRoute} />
		}

		if(route === Routes.RegistrationCompletePageRoute){
			return <RegistrationCompletePage setRoute={setRoute} />
		}
	}

	return (
		<div className="App d-flex flex-column">
			<header className='header d-flex flex-column flex-md-row pt-4 px-4 w-100 align-items-center'>
				<div className='logo d-flex flex-column align-center d-md-block'>
					<img src="/images/logo-1.png" />
				</div>
				<div className='navigation mx-auto mt-2 mt-md-0 me-md-0'>
					<a 
						href="https://vff.hyperglade.com/" 
						target="_blank" 
						className='btn btn-black'
						rel='noreferrer'
					>
						Back to NFT details page
					</a>
				</div>
			</header>
			<div className='container d-flex w-50 align-center'>
				{renderPage()}
			</div>
			<footer className='footer mt-2 mt-md-auto'>
				<div className='d-flex flex-column ms-auto me-auto me-md-4'>
					<h5 className='text-end mb-0'>Technology Partner</h5>
					<img src='/images/logo-3.png' />
				</div>
			</footer>
		</div>
	);
}

export default App;
