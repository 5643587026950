import React, { useContext } from "react";
import { Wallet } from "../solana/SolanaConnector";
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Keypair, SystemProgram, Transaction } from '@solana/web3.js';
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore"; 
import { FirebaseContext } from "../firebase/firebaseProvider";
import { Routes } from "../contants";
import { useEffect } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";


function ConnectWallet({ email, submitAddress, isLoading, error }){
    const { connection } = useConnection();
    const { publicKey } = useWallet();

    if(!publicKey || !email){
        return;
    }

    return (
        <div className="d-flex justify-content-center">
            {error && 
                <button 
                    className="btn btn-red" 
                    disabled
                    onClick={() => {}}>
                    ERROR: {error}
                </button>
            }
            {!error && 
                <button 
                    className="btn btn-red" 
                    disabled={isLoading}
                    onClick={() => submitAddress(email, publicKey.toString())}>
                    Submit
                </button>
            }
        </div>
        
    )
}

function WalletVerificationPage({ setRoute }){
    const [email, setEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(null);

    const firebase = useContext(FirebaseContext);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});

        const firebaseSignIn = async () => {
            if (isSignInWithEmailLink(firebase.auth, window.location.href)) {
                // Additional state parameters can also be passed via URL.
                // This can be used to continue the user's intended action before triggering
                // the sign-in operation.
                // Get the email if available. This should be available if the user completes
                // the flow on the same device where they started it.
                let email = params.email;
                if (!email) {
                  // User opened the link on a different device. To prevent session fixation
                  // attacks, ask the user to provide the associated email again. For example:
                  email = window.prompt('Please provide your email for confirmation');
                }
                // The client SDK will parse the code from the link for you.
                try{
                    const result = await signInWithEmailLink(firebase.auth, email, window.location.href);
                    console.log(result)

                    setEmail(email);
                    setIsAuthenticated(true);
                    setError(null);
                }catch(e){
                    console.error("Error in login", e);
                    setEmail(null);
                    setIsAuthenticated(false);
                    setError("Your registration code is invalid or expired!");
                }
            }
        }

        firebaseSignIn();
    }, []);

    const submitAddress = async (email, publicKey) => {
        setIsLoading(true);
        try {
            const docRef = await addDoc(collection(firebase.database, "user-wallets"), {
                email,
                publicKey,
                createdAt: Date.now()
            });
            console.log("Document written with ID: ", docRef.id);

            setRoute(Routes.RegistrationCompletePageRoute);
        } catch (e) {
            console.error("Error adding document: ", e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="page d-flex flex-column align-items-center">
            <div className="badge">
                Email Verified
            </div>
            <h3 className="text-center">Please use Connect Wallet button to identify your Solana wallet</h3>
            <h6 className="text-center mt-1">Not sure how to proceed? Click here</h6>
            
            <div className="mt-4 d-flex flex-column">
                {isAuthenticated && 
                    <Wallet>
                        <ConnectWallet 
                            email={email} 
                            submitAddress={submitAddress}  
                            isLoading={isLoading}
                            error={error}
                        />
                    </Wallet>
                }

                {!isAuthenticated && !error && 
                    <p>Validating the login ...</p>
                }

                {!isAuthenticated && error && 
                    <p className="text-dark">ERROR: {error}</p>
                }
            </div>
            <p className="text-center mt-4" style={{ fontSize: "0.8em" }}>
                This page verifies you to be an attendee of VFF FinTech Forum 2022. 
                Please enter the email that you used to sign up for the event. 
                Kindly avoid providing any sensitive information such as your passwords, private keys etc. to third parties. 
                This is a free NFT airdrop, and you will not be charged for the transaction.
            </p>
        </div>
    )
}

export default WalletVerificationPage;