import React from "react";

function RegistrationCompletePage({ }){
    return (
        <div className="page d-flex flex-column flex-md-row align-items-center">
            <div className="left">
                <img src="/images/promo.gif" width="300px" />
            </div>
            <div className="right mt-2 mt-md-0 text-center text-md-left ms-md-4">
                <p>
                    Thank you! Your NFT will be airdropped to your wallet with an email notification.
                </p>
            </div>
        </div>
    )
}

export default RegistrationCompletePage;