import React from "react";

function EmailVerificationPage({ }){
    return (
        <div className="page d-flex flex-column align-items-center">
            <img src="/images/send.png" width="100px" />
            <h3 className="mt-4 text-center">
                Please use the link sent to your email address to proceed.
            </h3>
        </div>
    )
}

export default EmailVerificationPage;