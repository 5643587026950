import React from "react";
import { sendSignInLinkToEmail } from "firebase/auth";
import { useState } from "react";
import { useContext } from "react";
import { FirebaseContext } from "../firebase/firebaseProvider";
import { Routes } from "../contants";

function EnterEmailPage({ setRoute }){
    const firebase = useContext(FirebaseContext);
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const sendVerificationEmail = async () => {
        setIsLoading(true);
        try{
            const actionCodeSettings = {
                url: `${window.location.origin}/verify?email=${email}`,
                handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(firebase.auth, email, actionCodeSettings);
            setRoute(Routes.EmailVerificationPageRoute);
        }catch (e){
            console.error("Error in registering with email", e);
        }finally{
            setIsLoading(false);
        }
    }

    return (
        <div className="page d-flex flex-column align-items-center">
            <h3 className="text-center">Please enter your email address</h3>
            <input 
                className="form-control mt-2 w-100" 
                type="text" 
                onChange={e => setEmail(e.target.value)} 
                value={email}
            />
            <div className="mt-4">
                <button 
                    className="btn btn-red" 
                    onClick={sendVerificationEmail}
                    disabled={isLoading}
                >Submit</button> 
            </div>
            <p className="text-center mt-4" style={{ fontSize: "0.8em" }}>
                This page verifies you to be an attendee of VFF FinTech Forum 2022. 
                Please enter the email that you used to sign up for the event. 
                Kindly avoid providing any sensitive information such as your passwords, private keys etc. to third parties. 
                This is a free NFT airdrop, and you will not be charged for the transaction.
            </p>
        </div>
    )
}

export default EnterEmailPage;